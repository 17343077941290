import repairProductProfile from '@/api/customer-service/repair-product-profile'

import { baseState, baseMutations } from '@/store/mixins'
import { split, isEmpty, includes } from 'lodash'

export const state = {
  ...baseState,
  formViewDatas: [],
  branch_id: [],
  repair_status: undefined
}

export const getters = {
  getRepairStatusList: state => repair_status => {
    if (repair_status) {
      if (repair_status == 2 || repair_status == 3) {
        return state.formViewDatas.repairProductProfilesStatus
          .filter(el => includes([2, 3], el.repair_status_id))
      } else if (repair_status == 6 || repair_status == 7) {
        return state.formViewDatas.repairProductProfilesStatus
          .filter(el => includes([6, 7], el.repair_status_id))
      }
    } else {
      return state.formViewDatas.repairProductProfilesStatus
    }
  },
  getUom: state => productId => {
    if (productId) {
      return state.formViewDatas.product
        .filter(p => p.product_id == productId)
        .map(el => el.uom_category.unit_of_measures)
        .flat()
    }
    return state.formViewDatas.uom
  },
  isDefaultUom: state => productId => {
    if (productId) {
      return state.formViewDatas.product
        .filter(p => p.product_id == productId)
        .map(el => el.uom_category.unit_of_measures)
        .flat()
        .filter(d => d.is_default == true)
        .map(el => el.uom_id)
        .reduce((init, value) => (init = value))
    }
    return null
  },
}

export const mutations = {
  ...baseMutations,
  SET_BRANCH_ID(state, value) {
    state.branch_id = value
  },
  SET_REPAIR_STATUS(state, value) {
    state.repair_status = value
  },
  SET_VIEW_MODELS(state, { data, index }) {
    if (!index) {
      state.formViewDatas = data;
    } else {
      split(index, ",").forEach(el => {
        state.formViewDatas[el] = data[el];
      });
    }
  },
}

export const actions = {
  fetch(context, attrubutes = {}) {
    const params = Object.assign(
      {
        page: context.state.pagination.page,
        perPage: context.state.pagination.perPage,
        filter: {
          branch_id: context.state.branch_id,
          search: context.state.search,
          repair_status: context.state.repair_status,
        }
      },
      attrubutes
    )
    return repairProductProfile.get({ params }).then(response => {
      context.commit('RECEIVE_RESOURCES', response)
      return Promise.resolve(response)
    })
  },
  store(context, data) {
    return repairProductProfile.store(data)
  },
  update(context, { id, data }) {
    return repairProductProfile.update(id, data)
  },
  updateRepairStatus(context, { id, data }) {
    return repairProductProfile.updateRepairStatus(id, data)
  },
  async find(context, id) {
    if (isEmpty(context.state.edit_data)) {
      let response = await repairProductProfile.find(id)
      context.commit('SET_EDIT_DATA', response.data)
    }
  },
  destroy(context, id) {
    return repairProductProfile.destroy(id)
  },
  async getFormViewModel(context, { params, index }) {
    let response = await repairProductProfile.formViewModel({ params });
    context.commit("SET_VIEW_MODELS", {
      data: response.data,
      index: index
    });
    return Promise.resolve(response);
  },
}
